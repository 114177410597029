
import Constants from './Constants';

import _1 from '../assets/img/carousel/1.png';
import _2 from '../assets/img/carousel/2.png';
import _3 from '../assets/img/carousel/3.png';
import _5 from '../assets/img/carousel/5.png';
import _6 from '../assets/img/carousel/6.png';
import _7 from '../assets/img/carousel/7.png';

export default [
    {
        image: _1,
        title: {
            pt_br: `Bem-vindo ao ${Constants.enterpriseName}`,
            en_us: `Welcome to ${Constants.enterpriseName}`,
            es_es: `Bienvenido a ${Constants.enterpriseName}`,
        },
        subtitle: {
            pt_br: "Contamos com uma infraestrutura completa para garantir a melhor experência de nossos hóspedes!",
            en_us: "We have a complete infrastructure to ensure the best experience for our guests!",
            es_es: "¡Contamos con una completa infraestructura para garantizar la mejor experiencia a nuestros huéspedes!",
        }
    }, {
        image: _2,
        title: {
            pt_br: "Reservas pelo nosso site",
            en_us: "Reservations through our website",
            es_es: "Reservas a través de nuestra web",
        },
        subtitle: {
            pt_br: "Garanta 5% off",
            en_us: "Guarantee 5% off",
            es_es: "Garantía 5% de descuento",
        }
    }, {
        image: _5,
        title: {
            pt_br: "Estadia prolongada",
            en_us: "Extended stay",
            es_es: "Estancia prolongada",
        },
        subtitle: {
            pt_br: "15% off reservando 7 noites ou mais",
            en_us: "15% off booking 7 nights or more",
            es_es: "15% de descuento reservando 7 noches o más",
        }
    }, {
        image: _6,
        title: {
            pt_br: "Reserva antecipada",
            en_us: "Advance booking",
            es_es: "Reserva anticipada",
        },
        subtitle: {
            pt_br: "Garanta 10% off",
            en_us: "Guarantee 10% off",
            es_es: "Garantía 10% de descuento"
        }
    }, {
        image: _3,
        title: {
            pt_br: "Valores especiais para empresas",
            en_us: "Special values for companies",
            es_es: "Valores especiales para empresas",
        },
        subtitle: {
            pt_br: "Quartos individuais e duplos. \nTarifas especiais a partir de 3 diárias",
            en_us: "Single and double rooms. \nSpecial rates from 3 nights",
            es_es: "Habitaciones individuales y dobles. \nPrecios especiales a partir de 3 noches",
        }
    }, {
        image: _7,
        title: {
            pt_br: "Experimente nosso café da manhã",
            en_us: "Try our breakfast",
            es_es: "Prueba nuestro desayuno",
        },
        subtitle: {
            pt_br: "Avulso para não hóspedes: R$ 35,00 por pessoa",
            en_us: "Single for non-guests: R$ 35.00 per person",
            es_es: "Sencilla para no huéspedes: R$ 35,00 por persona",
        }
    }
];