
export default {
    enterpriseName: "Hotel Binder Quality Inn Mogi Guaçu",
    address: {
        street: {
            pt_br: "Rua José de Campo, 15",
            en_us: "15, José de Campo street",
            es_es: "Calle José de Campo, 15"
        },
        city: "Mogi Guaçu, SP",
        country: {
            pt_br: "Brasil",
            en_us: "Brazil",
            es_es: "Brasil"
        },
        googleMapsLink: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2571.4624862731903!2d-46.941957797662845!3d-22.372780048457745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c85725885c7b3d%3A0x435ff9b2da86e395!2sBinder%20Hotel%20Mogi%20Gua%C3%A7u!5e0!3m2!1spt-BR!2sbr!4v1682211108216!5m2!1spt-BR!2sbr`
    },
    contact: {
        email: {
            icon: "fas fa-envelope",
            value: "reservas@bindermogiguacu.com.br",
            text: {
                pt_br: "E-mail",
                en_us: "E-mail",
                es_es: "E-mail"
            }
        },
        phone: {
            icon: "fas fa-phone-alt",
            value: "(19) 3362-3349",
            text: {
                pt_br: "Telefone",
                en_us: "Phone",
                es_es: "Teléfono"
            }
        },
        onlineReservation: {
            link: "https://api.whatsapp.com/send?phone=551933623380",
            text: {
                pt_br: "Reservas Online",
                en_us: "Online Reservations",
                es_es: "Reservas Online"
            }
        }
    },
    socialLinks: [
        {
            name: "WhatsApp",
            icon: "fab fa-whatsapp",
            link: "https://api.whatsapp.com/send?phone=551933623380",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Telegram",
            icon: "fab fa-telegram-plane",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Instagram",
            icon: "fab fa-instagram",
            link: "https://www.instagram.com/hotelbindermg/",
            toShow: true,
            toShowOnFloatingActionBar: true
        }, {
            name: "Facebook",
            icon: "fab fa-facebook-f",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Facebook Messenger",
            icon: "fab fa-messenger-f",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Twitter",
            icon: "fab fa-twitter",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "Skype",
            icon: "fab fa-skype",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "LinkedIn",
            icon: "fab fa-linkedin",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }, {
            name: "YouTube",
            icon: "fab fa-youtube",
            link: "#",
            toShow: false,
            toShowOnFloatingActionBar: false
        }
    ],
    developer: {
        link: "https://www.linkedin.com/in/reginaldo-savian-jr-045820141/",
        name: {
            pt_br: "SavX - Desenvolvimento de Sistemas",
            en_us: "SavX - Systems Development",
            es_es: "SavX - Desarrollo de Sistemas"
        },
        text: {
            pt_br: "Desenvolvido por",
            en_us: "Developed by",
            es_es: "Desarrollado por"
        }
    }
}
