

import _auditorio_1 from '../assets/img/events/auditorio-1.jpeg';
import _auditorio_2 from '../assets/img/events/auditorio-2.jpeg';
import _auditorio_3 from '../assets/img/events/auditorio-3.jpeg';


export default [
    {
        images: [
            _auditorio_1,
            _auditorio_2,
            _auditorio_3,
        ],
        name: {
            pt_br: "Auditório",
            en_us: "Auditorium",
            es_es: "Auditorio",
        },
        description: {
            pt_br: "O hotel possui auditório com capacidade para 150 pessoas para realização de seu evento com conforto e praticidade",
            en_us: "The hotel has an auditorium with capacity for 150 people to hold your event with comfort and practicality.",
            es_es: "El hotel cuenta con un auditorio con capacidad para 150 personas para realizar su evento con comodidad y practicidad.",
        }
    }
];