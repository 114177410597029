
export default {
    selectLanguage: {
        pt_br: "Selecione um idioma",
        en_us: "Select a language",
        es_es: "Selecciona un idioma",
    },
    home: {
        pt_br: "Início",
        en_us: "Start",
        es_es: "Comienzo",
    },
    events: {
        pt_br: "Eventos",
        en_us: "Events",
        es_es: "Eventos",
    },
    specials: {
        pt_br: "Especiais",
        en_us: "Specials",
        es_es: "Especiales"
    },
    services: {
        pt_br: "Serviços",
        en_us: "Services",
        es_es: "Servicios"
    },
    gallery: {
        pt_br: "Galeria",
        en_us: "Gallery",
        es_es: "Galería"
    },
    about: {
        pt_br: "Sobre",
        en_us: "About",
        es_es: "Sobre"
    },
    accommodations: {
        pt_br: "Acomodações",
        en_us: "Accommodations",
        es_es: "Alojamientos"
    },
    internalLinks: {
        pt_br: "Links Internos",
        en_us: "Internal Links",
        es_es: "Vínculos Internos"
    },
    ourSocialNetworks: {
        pt_br: "Nossas mídias sociais",
        en_us: "Our social media",
        es_es: "Nuestras redes sociales"
    },
    ourSocialNetworksDescription: {
        pt_br: "Entre em contato conosco através de uma de nossas mídias sociais",
        en_us: "Get in touch with us through one of our social media",
        es_es: "Ponte en contacto con nosotros a través de una de nuestras redes sociales"
    },
    copyright: {
        pt_br: "© Copyright 2019. Todos os direitos reservados",
        en_us: "© Copyright 2019. All rights reserved",
        es_es: "© Copyright 2019. Todos los derechos reservados"
    },
    servicesTitle: {
        pt_br: "Confira nossos serviços",
        en_us: "Check out our services",
        es_es: "Consulta nuestros servicios"
    },
    servicesDescription: {
        pt_br: "O Binder Hotel Quality Inn Mogi Guaçu está localizado no centro da cidade, com fácil acesso a comércio, transporte e passeios turísticos. A propriedade ainda oferece, internet wi-fi gratuita, quartos com banheiros privativos, minibar, TV a cabo. Além do buffet de café da manhã variado servido diariamente no restaurante.",
        en_us: "The Binder Hotel Quality Inn Mogi Guaçu is located in the center of the city, with easy access to shopping, transportation and sightseeing. The property also offers free wi-fi internet, rooms with private bathrooms, minibar, cable TV. In addition to the varied breakfast buffet served daily in the restaurant.",
        es_es: "El Binder Hotel Quality Inn Mogi Guaçu está ubicado en el centro de la ciudad, con fácil acceso a tiendas, transporte y lugares de interés turístico. La propiedad también ofrece internet wi-fi gratuito, habitaciones con baño privado, minibar, televisión por cable. Además del variado desayuno buffet que se sirve a diario en el restaurante."
    },
    galleryTitle: {
        pt_br: "Conheça um pouco de nossa estrutura que está esperando por você!",
        en_us: "Get to know a little of our structure that is waiting for you!",
        es_es: "¡Conoce un poco de nuestra estructura que te está esperando!"
    },
    galleryDescription: {
        pt_br: "",
        en_us: "",
        es_es: ""
    },
    accommodationsTitle: {
        pt_br: "Conforto e comodidade para você!",
        en_us: "Comfort and convenience for you!",
        es_es: "Comodidad y comodidad para ti!"
    },
    accommodationsDescription: {
        pt_br: "Apartamentos que possuem uma estrutura completa para garantir seu conforto, com TV a cabo, ar condicionado, wi-fi gratuito e minibar",
        en_us: "Apartments that have a complete structure to guarantee your comfort, with cable TV, air conditioning, free wi-fi and minibar",
        es_es: "Departamentos que cuentan con una estructura completa para garantizar su comodidad, con TV por cable, aire acondicionado, wi-fi gratuito y minibar"
    },
    aboutTitle: {
        pt_br: "Conheça mais sobre nós",
        en_us: "Know more about us",
        es_es: "Conozca más sobre nosotros"
    },
    aboutDescription: {
        pt_br: "A rede de hotéis Binder nasceu com o Binder Mogi das Cruzes que foi inaugurado no ano de 1979, onde no local havia uma igreja localizada em frente ao atual largo do Rosário, onde ficava a igreja de mesmo nome.\nO Binder Mogi Guaçu está localizado no antigo hotel Baradah, no centro da cidade, facilitando muito o acesso a várias lojas, restaurantes, farmácias e a Rodoviária de Mogi Guaçu.\nO hotel foi reformado e iniciou suas operações sob nova gerência, visão e conceito, oferecendo um delicioso café da manhã, bem diversificado e servido diariamente no restaurante, além de internet wi-fi gratuita, quartos com banheiros privativos, minibar e TV a cabo.",
        en_us: "The Binder hotel chain was born with the Binder Mogi das Cruzes, which opened in 1979, where there was a church located in front of the current Largo do Rosário, where the church of the same name was located.\nThe Binder Mogi Guaçu is located in the old Baradah hotel, in the center of the city, making access to several stores, restaurants, pharmacies and the Mogi Guaçu Bus Station very easy.\nThe hotel was renovated and started operations under a new management, vision and concept, offering a delicious breakfast morning, well diversified and served daily in the restaurant, in addition to free wi-fi internet, rooms with private bathrooms, minibar and cable TV.",
        es_es: "La cadena hotelera Binder nació con el Binder Mogi das Cruzes, inaugurado en 1979, donde había una iglesia ubicada frente al actual Largo do Rosário, donde estaba ubicada la iglesia del mismo nombre.\nSe encuentra el Binder Mogi Guaçu en el antiguo hotel Baradah, en el centro de la ciudad, facilitando el acceso a varias tiendas, restaurantes, farmacias y a la estación de autobuses de Mogi Guaçu.\nEl hotel fue renovado e inició operaciones bajo una nueva dirección, visión y concepto, ofreciendo un delicioso desayuno por la mañana, bien diversificado y servido diariamente en el restaurante, además de internet wi-fi gratuito, habitaciones con baño privado, minibar y TV por cable."
    },
    eventsTitle: {
        pt_br: "Agende seu evento conosco!",
        en_us: "Schedule your event with us!",
        es_es: "Agenda tu evento con nosotros!"
    },
    eventsDescription: {
        pt_br: "O hotel possui auditório com capacidade para 150 pessoas para realização de seu evento com conforto e praticidade",
        en_us: "The hotel has an auditorium with capacity for 150 people to hold your event with comfort and practicality.",
        es_es: "El hotel cuenta con un auditorio con capacidad para 150 personas para realizar su evento con comodidad y practicidad.",
    },
    specialsTitle: {
        pt_br: "Viva momentos especiais com a gente",
        en_us: "Live special moments with us",
        es_es: "Vive momentos especiales con nosotros"
    },
    specialsDescription: {
        pt_br: "Momentos especiais pedem por cuidados especiais! Deixe que nós cuidamos de tudo para que seu dia seja ainda mais perfeito!",
        en_us: "Special moments ask for special care! Let us take care of everything to make your day even more perfect!",
        es_es: "¡Momentos especiales piden un cuidado especial! ¡Déjanos encargarnos de todo para que tu día sea aún más perfecto!"
    }
}
