
import _default from '../assets/img/default.png';
import _casal1 from '../assets/img/bedrooms/casal-1.jpeg';
import _casal2 from '../assets/img/bedrooms/casal-2.jpeg';
import _casal3 from '../assets/img/bedrooms/casal-3.jpeg';
import _casal4 from '../assets/img/bedrooms/casal-4.jpeg';
import _casal5 from '../assets/img/bedrooms/casal-5.jpeg';
import _duplo1 from '../assets/img/bedrooms/duplo-1.jpeg';
import _duplo2 from '../assets/img/bedrooms/duplo-2.jpeg';
import _duplo3 from '../assets/img/bedrooms/duplo-3.jpeg';
import _duplo4 from '../assets/img/bedrooms/duplo-4.jpeg';


export default [
    {
        images: [
            _casal1,
            _casal2,
            _casal3,
            _casal4,
            _casal5,
        ],
        name: {
            pt_br: "Casal",
            en_us: "Couple",
            es_es: "Pareja"
        },
        description: {
            pt_br: "Possui uma cama de casal, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar",
            en_us: "It has a double bed, cable TV, air conditioning, free wi-fi, work desk and minibar",
            es_es: "Cuenta con cama doble, TV por cable, aire acondicionado, wi-fi gratis, mesa de trabajo y minibar",
        }
    }, {
        images: [
            _duplo1,
            _duplo2,
            _duplo3,
            _duplo4,
        ],
        name: {
            pt_br: "Solteiro Duplo",
            en_us: "Double Single",
            es_es: "Doble Individual",
        },
        description: {
            pt_br: "Possui duas camas de solteiro, TV a cabo, ar condicionado, wi-fi gratuito, mesa para trabalho e minibar",
            en_us: "It has two single beds, cable TV, air conditioning, free wi-fi, work desk and minibar",
            es_es: "Cuenta con dos camas individuales, TV por cable, aire acondicionado, wi-fi gratuito, mesa de trabajo y minibar",
        }
    }
];
