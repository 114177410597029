
import _galeria_1 from '../assets/img/gallery/galeria-1.jpeg';
import _galeria_2 from '../assets/img/gallery/galeria-2.jpeg';
import _galeria_3 from '../assets/img/gallery/galeria-3.jpeg';
import _galeria_4 from '../assets/img/gallery/galeria-4.jpeg';
import _galeria_5 from '../assets/img/gallery/galeria-5.jpeg';
import _galeria_6 from '../assets/img/gallery/galeria-6.jpeg';
import _galeria_7 from '../assets/img/gallery/galeria-7.jpeg';
import _galeria_8 from '../assets/img/gallery/galeria-8.jpeg';
import _galeria_9 from '../assets/img/gallery/galeria-9.jpeg';
import _galeria_10 from '../assets/img/gallery/galeria-10.jpeg';
import _galeria_11 from '../assets/img/gallery/galeria-11.jpeg';
import _galeria_12 from '../assets/img/gallery/galeria-12.jpeg';
import _galeria_13 from '../assets/img/gallery/galeria-13.jpeg';
import _galeria_14 from '../assets/img/gallery/galeria-14.jpeg';
import _galeria_15 from '../assets/img/gallery/galeria-15.jpeg';
import _galeria_16 from '../assets/img/gallery/galeria-16.jpeg';
import _galeria_17 from '../assets/img/gallery/galeria-17.jpeg';
import _galeria_18 from '../assets/img/gallery/galeria-18.jpeg';
import _galeria_19 from '../assets/img/gallery/galeria-19.jpeg';
import _galeria_20 from '../assets/img/gallery/galeria-20.jpeg';
import _galeria_21 from '../assets/img/gallery/galeria-21.jpeg';
import _galeria_22 from '../assets/img/gallery/galeria-22.jpeg';
import _galeria_23 from '../assets/img/gallery/galeria-23.jpeg';


export default [
    {
        images: [
            _galeria_1,
            _galeria_2,
            _galeria_3,
            _galeria_4,
            _galeria_5,
            _galeria_6,
            _galeria_7,
            _galeria_8,
            _galeria_9,
            _galeria_10,
            _galeria_11,
            _galeria_12,
            _galeria_13,
            _galeria_14,
            _galeria_15,
            _galeria_16,
            _galeria_17,
            _galeria_18,
            _galeria_19,
            _galeria_20,
            _galeria_21,
            _galeria_22,
            _galeria_23,
        ],
        name: {
            pt_br: "Galeria",
            en_us: "Gallery",
            es_es: "Galería"
        }, 
        description: {
            pt_br: "",
            en_us: "",
            es_es: ""
        }
    }
];