
import _default from '../assets/img/default.png';
import _breakfast1 from '../assets/img/specials/breakfast-1.jpeg';
import _breakfast2 from '../assets/img/specials/breakfast-2.jpeg';
import _breakfast3 from '../assets/img/specials/breakfast-3.jpeg';
import _breakfast4 from '../assets/img/specials/breakfast-4.jpeg';
import _breakfast5 from '../assets/img/specials/breakfast-5.jpeg';
import _breakfast6 from '../assets/img/specials/breakfast-6.jpeg';



export default [
    {
        images: [
            _breakfast1,
            _breakfast2,
            _breakfast3,
            _breakfast4,
            _breakfast5,
            _breakfast6,
        ],
        name: {
            pt_br: "Breakfast",
            en_us: "Breakfast",
            es_es: "Desayuno"
        },
        description: {
            pt_br: "No Binder tanto hóspedes quanto não hóspedes podem desfrutar do nosso café da manhã, mais de 40 itens preparados pela nossa equipe de gastronomia. Um dos melhores cafés de Mogi das Cruzes pronto para te receber com um café especial e sair da rotina de uma forma deliciosa",
            en_us: "At Binder both guests and non-guests can enjoy our breakfast, more than 40 items prepared by our gastronomy team. One of the best cafes in Mogi das Cruzes ready to welcome you with a special coffee and get out of the routine in a delicious way",
            es_es: "En Binder tanto huéspedes como no huéspedes pueden disfrutar de nuestros desayunos, más de 40 platos elaborados por nuestro equipo de gastronomía. Una de las mejores cafeterías de Mogi das Cruzes lista para recibirte con un café especial y salir de la rutina de una manera deliciosa"
        }
    }
];