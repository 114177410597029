// Importando Popperjs, Bootstrap e Icons
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

import "./_globals.css";
import iconLogo from "./assets/img/icon-logo.png";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import Constants from './data/Constants';


document.title = Constants.enterpriseName;
document.getElementById("favicon").href = iconLogo;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
